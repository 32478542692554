import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import AlumniGrid from '../components/alumniGrid';
import { brothers } from '../components/FilteredBros';
import './brothers.scss';

const Alumni = props => {
  const alumniBrothers = brothers.filter(bro => bro.alumni); // Filter for alumni

  return (
    <Layout>
      <Helmet>
        <title>Alumni</title>
        <meta name="description" content="Alumni Page" />
      </Helmet>
      <section id="banner" className="major">
        <div className="inner">
          <h1 align="center">Meet the Alumni of ΣΝ!</h1>
        </div>
      </section>
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <AlumniGrid brothers={alumniBrothers} includeRole={true} showFilters={true} groupByYear={true} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Alumni;
