import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';

import Brother from './brother';
import { brothers as allBrothers } from './FilteredBros';
import CourseSelect from './CourseSelect';
import StateSelect from './StateSelect';
import './brother.scss';

export const CLEAR_YEAR_FILTER = 1;

function isFilterable(brothers) {
  if (!brothers) return true;
  return brothers.every(bro => bro.year && bro.hometown && bro.major);
}

const AlumniGrid = ({ brothers: propsBrothers, includeRole = true, showFilters = true, groupByYear = true, sortAlphabetically = true }) => {
  const [stateFilter, setStateFilter] = useState('');
  const [courseFilter, setCourseFilter] = useState('');
  const [yearFilter, setYearFilter] = useState(CLEAR_YEAR_FILTER);
  const [visibleBros, setVisibleBros] = useState(propsBrothers ? propsBrothers : allBrothers);
  const [canFilter, setCanFilter] = useState(false);

  useEffect(() => {
    const brothersToFilter = propsBrothers ? propsBrothers : allBrothers;
    setCanFilter(isFilterable(brothersToFilter));
  }, [propsBrothers]);

  useEffect(() => {
    const brothersToFilter = propsBrothers ? propsBrothers : allBrothers;
    const filteredBros = brothersToFilter.filter(bro => {
      const yearMatches = yearFilter === CLEAR_YEAR_FILTER || bro.year === yearFilter;
      const stateMatches = stateFilter === '' || bro.hometown.endsWith(stateFilter);
      const courseMatches = courseFilter === '' || bro.major.includes(courseFilter);
      return yearMatches && stateMatches && courseMatches;
    });

    if (sortAlphabetically) {
      filteredBros.sort((a, b) => a.name.localeCompare(b.name));
    }

    setVisibleBros(filteredBros);
  }, [yearFilter, stateFilter, courseFilter, propsBrothers, sortAlphabetically]);

  const resetFilters = () => {
    setYearFilter(CLEAR_YEAR_FILTER);
    setCourseFilter('');
    setStateFilter('');
  };

  const groupByYearFunc = bros => {
    const groups = {};
    bros.forEach(bro => {
      const groupKey = bro.year;
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(bro);
    });
    return groups;
  };

  const groupedBros = groupByYear ? groupByYearFunc(visibleBros) : { all: visibleBros };

  // Sort the groups by year in descending order
  const sortedYears = Object.keys(groupedBros).sort((a, b) => b - a);

  return (
    <div>
      <Container>
        {showFilters && (
          <>
            <div className="buttons">
              <button onClick={() => resetFilters()}>Clear Filters</button>
            </div>
            <div className="filters">
              <StateSelect
                stateFilter={stateFilter}
                stateSet={new Set(visibleBros.map(bro => bro.hometown.split(',')[1]))}
                setStateFilter={setStateFilter}
                placeholder={stateFilter === '' ? 'All States' : stateFilter}
              />
              <CourseSelect
                courseFilter={courseFilter}
                courseSet={new Set(visibleBros.map(bro => bro.major).flat())}
                setCourseFilter={setCourseFilter}
                placeholder={courseFilter === '' ? 'All Courses' : courseFilter}
              />
            </div>
          </>
        )}
      </Container>
      <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end', margin: '64px 0 16px 0' }}></div>
      <div>
        {sortedYears.map(group => (
          groupedBros[group]?.length > 0 && (
            <div key={group}>
              <h2>Class of {group}</h2>
              <div className={'cards'}>
                {groupedBros[group].map((bro, index) => (
                  <Brother key={`${index}`} brother={bro} includeRole={includeRole} />
                ))}
              </div>
              <div style={{ margin: '50px 0' }}></div>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default AlumniGrid;
